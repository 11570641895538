<template>
  <svg viewBox="0 0 24 24">
    <path
      d="M 7 11 H 17 V 13 H 7 V 11 Z M 12 22 C 6.477 22 2 17.523 2 12 C 2 6.477 6.477 2 12 2 C 17.523 2 22 6.477 22 12 C 22 17.523 17.523 22 12 22 Z M 12 20 C 14.1217 20 16.1566 19.1571 17.6569 17.6569 C 19.1571 16.1566 20 14.1217 20 12 C 20 9.8783 19.1571 7.8434 17.6569 6.3431 C 16.1566 4.8429 14.1217 4 12 4 C 9.8783 4 7.8434 4.8429 6.3431 6.3431 C 4.8429 7.8434 4 9.8783 4 12 C 4 14.1217 4.8429 16.1566 6.3431 17.6569 C 7.8434 19.1571 9.8783 20 12 20 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "MinusIcon",
};
</script>
